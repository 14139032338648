<template>
    <div class="bg-gray-200 p-1">
        <div class="max-w-screen-lg mx-auto">
            <div v-if="waiting" class="py-48">
                <b-notification class="p-8 bg-medclub-blue-200" :closable="false">
                    <b-loading :is-full-page="false" v-model="waiting"></b-loading>
                </b-notification>
            </div>
            <div v-else-if="selected">
                <div class="flex items-center p-1">
                    <div class="w-2/12">
                        <a href="javascript:;" @click="selected = null">
                            <b-icon
                                class="text-3xl text-medclub-blue-400"
                                pack="jam"
                                icon="jam-chevron-left"
                            />
                        </a>
                    </div>
                    <div class="w-8/12 text-center">
                        <h1 class="font-bold text">{{ selected.nm_fantasia }}</h1>
                    </div>
                </div>
                <div class="bg-white mb-4 flex flex-wrap items-center">
                    <div class="w-full md:w-1/2 md:p-2">
                        <img class="mx-auto w-full max-w-sm md:rounded-lg" :src="selected.aq_foto" :alt="selected.nm_fantasia">
                    </div>                    
                    <div class="w-full md:w-1/2 p-2">
                        <h1 class="font-bold text-lg">{{ selected.nm_fantasia }}</h1>
                        <h2 class="font-bold text-xs text-medclub-blue-400 mt-2">INFORMAÇÕES</h2>
                        <div class="whitespace-pre-wrap text-sm" v-html="selected.ds_observacao"></div>
                        <h2 class="font-bold text-xs text-medclub-blue-400 mt-2">TELEFONE</h2>
                        <div class="flex items-center text-sm">
                            <b-icon icon="phone" />
                            <p>{{ $root.applyTelefoneMask(selected.nr_telefone) }}</p>
                        </div>
                        <h2 class="font-bold text-xs text-medclub-blue-400 mt-2">ENDEREÇO</h2>
                        <div class="flex items-center text-sm">
                            <b-icon icon="map-marker" />
                            <p>{{ selected.nm_rua }}, {{ selected.nr_estabelecimento }}, {{ selected.nm_bairro }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-for="(estabelecimento, idx) in list" :key="idx" class="p-1">
                    <div class="flex items-center p-1 bg-white shadow-md rounded-lg border cursor-pointer" @click="selected = estabelecimento">
                        <div class="w-3/12 md:w-1/12 flex justify-center">
                            <div class="w-12 h-12 bg-gray-200 rounded-full bg-center bg-cover bg-no-repeat" :style="`background-image: url('${ estabelecimento.aq_foto }')`"></div>
                        </div>
                        <div class="w-8/12 md:w-10/12 text-sm">
                            <h1 class="font-bold">{{ estabelecimento.nm_fantasia }}</h1>
                            <p>{{ estabelecimento.nm_rua }}, {{ estabelecimento.nr_estabelecimento }}, {{ estabelecimento.nm_bairro }}</p>
                            <p>{{ $root.applyTelefoneMask(estabelecimento.nr_telefone) }}</p>
                        </div>
                        <div class="w-1/12 flex justify-center">
                            <b-icon class="text-3xl text-gray-500" pack="jam" icon="jam-chevron-right" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Utils } from '../../middleware'
export default {
    data () {
        return {
            list: [],
            selected: null,
            waiting: false
        }
    },
    async created () {
        this.waiting = true
        var params ={
            cd_cidade: this.$store.state.cdCidade
        }
        if(JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))) params['ie_atende_medclub_empresa'] =  JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))

        const response = await Utils.getRedeCredenciada(params)
        this.waiting = false
        if (response.status === 200) this.list = response.data
    }
}
</script>